import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Modal from "./components/Modal";
import Terms from "./components/Terms";
import "sweetalert2/src/sweetalert2.scss";
import Privacy from "./components/Privacy";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import MobileNav from "./components/MobileNav";
function App() {
  return (
    <Router>
      <Navbar />
      <MobileNav />
      <Modal />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/terms-of-service" render={() => <Terms />} />
        <Route exact path="/privacy-policy" render={() => <Privacy />} />
        <Route exact path="/about" render={() => <About />} />
      </Switch>
    </Router>
  );
}

export default App;
