import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const Component = styled.div`
  margin: 40px auto;
  width: 50%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    padding: 20px;
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    @media (max-width: 768px) and (min-width: 500px) {
      font-size: 16px;
      padding: 15px;
    }
    @media (max-width: 500px) {
      padding: 10px;
      max-width: 80px;
      min-width: 80px;
    }
    @media (max-width: 350px) {
      max-width: 60px;
      min-width: 60px;
    }
    .numbers {
      display: flex;
      margin: 0 2px;
      height: 46px;
      width: 38px;
      font-size: 40px;
      letter-spacing: 0.2px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      @media (max-width: 768px) and (min-width: 500px) {
        font-size: 30px;
        height: 38px;
        width: 32px;
      }
      @media (max-width: 500px) and (min-width: 350px) {
        height: 35px;
        width: 30px;
        font-size: 25px;
      }
      @media (max-width: 350px) {
        height: 30px;
        width: 28px;
        font-size: 20px;
      }
    }
    .label {
      margin-top: 8px;
      font-style: normal;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.2px;
      color: #000;
      @media (max-width: 768px) and (min-width: 500px) {
        font-size: 16px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
`;

const Countdown = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMins, setTimerMins] = useState("00");
  const [timerSec, setTimerSec] = useState("00");

  let interval = useRef();
  const startTimer = () => {
    const countdownDate = new Date("Mar 8 2022 00:00:00").getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMins(minutes);
        setTimerSec(seconds);
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <Component>
      <div className="time">
        <div className="numbers">
          {timerDays
            .toString()
            .split("")
            .map((item) => {
              timerDays.toString().split().length <= 1 && <span>0</span>;
              return item;
            })}
        </div>
        <div className="label">Days</div>
      </div>
      <div className="time">
        <div className="numbers">
          {timerHours.toString().length === 1 && "0"}
          {timerHours}
        </div>
        <div className="label">Hours</div>
      </div>
      <div className="time">
        <div className="numbers">
          {timerMins.toString().length === 1 && "0"}
          {timerMins}
        </div>
        <div className="label">Minutes</div>
      </div>
      <div className="time">
        <div className="numbers">
          {timerSec.toString().length === 1 && "0"}
          {timerSec}
        </div>
        <div className="label">Seconds</div>
      </div>
    </Component>
  );
};

export default Countdown;
