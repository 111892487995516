import React from "react";
import Footer from "./Footer";
import styled from "styled-components";
import { Blank } from ".";
import TermsPolicyHeader from "./TermsPolicy";

const TermComponent = styled.div`
  color: #fff;
  max-width: 1200px;
  min-height: calc(100vh- 100px);
  margin: 0 auto;
  background: #210045;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 17px;
    margin: 0 10px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    margin: 0 10px;
  }
`;

const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
`;
const Header = styled.div`
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 150px;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 35px;
  }
`;
const Block = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0.8;
  margin: 10px 0;
  font-family: "Poppins", "Montserrat", sans-serif;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 15px;
    line-height: 26px;
  }
  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
  }
`;
const Content = styled.div`
  padding: 0 20px;
  background-color: #210045;
  min-height: calc(100vh - 100px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) and (min-width: 500px) {
  }
  @media (max-width: 500px) {
    padding: 0 10px;
  }
  .bg-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 0;
  }
  .bg-image.purple {
    top: 150px;
    left: 12%;
    width: 140px;
    height: 140px;
    svg {
      width: 140px;
      height: 140px;
    }
  }
  .bg-image.dots {
    top: 250px;
    right: 12%;
    width: 100px;
    height: 100px;
    svg {
      width: 80px;
      opacity: 0.5;
      height: 80px;
    }
  }
`;
const LinkBlock = styled.div`
  border-left: 8px solid #ffffff;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 30px 0;
  color: #ffffff88;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const Terms = () => {
  document.documentElement.translate = "yes";
  return (
    <>
      <Blank />
      <TermComponent>
        <Content>
          <Background>
            <span class="bg-image dots">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.76 29.76">
                <defs>
                  <style></style>
                </defs>
                <title>dots</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle fill="#fff" cx="10.21" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="28.91" r="0.85" />
                  </g>
                </g>
              </svg>
            </span>
            <span class="bg-image purple">
              <svg viewBox="0 0 68.76 68.76">
                <defs>
                  <linearGradient
                    id="purple"
                    x1="34.17"
                    y1="59.32"
                    x2="34.65"
                    y2="2.6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#19073b" />
                    <stop offset="0.14" stop-color="#370e4e" />
                    <stop offset="0.49" stop-color="#7f1e7b" />
                  </linearGradient>
                </defs>
                <title>pink</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle
                      fill="url(#purple)"
                      cx="34.38"
                      cy="34.38"
                      r="34.38"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </Background>
          <Header>Terms of Service</Header>
          <Block>
            These Terms of Service constitute an agreement (this “Agreement”)
            for the provision of Cloud Services by Metricks Inc. and any person
            or entity opening an online account with tricks Inc (“Customer”) as
            specified in the information provided by Customer to Metricks in the
            online registration form. These Terms and Conditions ("Terms",
            "Terms and Conditions") govern your relationship with Metricks
            website (the "Service") operated by Metricks Inc ("us", "we", or
            "our"). This Agreement is effective as of the date Customer clicks
            “Accepted and Agreed To” or a similar button in the process of
            opening an online account with Metricks Inc(the “Effective Date”).
            Customer’s use of and Metricks’s provision of the Cloud Services and
            Metricks System (each as defined below) are governed by this
            Agreement.
          </Block>
          <LinkBlock>
            This Agreement is effective as of the date Customer clicks “Accepted
            and Agreed To” or a similar button in the process of opening an
            online account with Metricks Inc (the “Effective Date”). Customer’s
            use of and Metricks’s provision of the Cloud Services and Metricks
            System (each as defined below) are governed by this Agreement.
          </LinkBlock>
          <TermsPolicyHeader sub_head="1. Website Terms and Conditions of Use" />
          <Block>
            Please read these Terms of Service carefully before using the
            Service as they contain important information regarding the legal
            rights, remedies and obligations. Your access to and use of the
            Service is conditioned on your acceptance of and compliance with
            these Terms. These Terms apply to all visitors, users and others who
            access or use the Service.
          </Block>
          <Block>
            By accessing this Website, accessible from http://Metricks.io, you
            are agreeing to be bound by these Website Terms and Conditions of
            Use and agree that you are responsible for the agreement with any
            applicable local laws. If you disagree with any of these terms, you
            are prohibited from accessing this site. The materials contained in
            this Website are protected by copyright and trademark law. You agree
            that Metricks reserves the right to update and change these Terms
            from time to time without notice.
          </Block>
          <Block>
            Any new updates, features or options that will be added to the
            Service, including the release of new tools and resources, shall be
            subject to these Terms. Please note that these Terms may be amended
            from time to time. In continuing to use the Service you confirm that
            you accept the then-current terms and conditions in full at the time
            you use the Service.
          </Block>
          <TermsPolicyHeader sub_head="2. Definitions" />
          <Block>
            Metricks is a company that enables advertisers (“Advertisers”) to
            market their products online with the help of publishers
            (“Affiliates”). Metricks contracts only with Advertisers and have no
            responsibility in the relationship between Advertisers and
            Affiliates. Metricks offers tools to improve, register and
            facilitate online marketing through Affiliates (the “Service”). The
            payment of Affiliates shall always be the sole responsibility of the
            Advertisers and Metricks shall therefore never be held liable for
            any damages resulting from any failure of the Advertiser to pay an
            Affiliate in a complete and timely manner.
          </Block>
          <Block>
            By accepting these Terms, any Advertiser using our Service warrants
            that he will pay the relevant Affiliate in a complete and timely
            manner.
          </Block>
          <TermsPolicyHeader sub_head="3. Registration" />
          <Block>
            To make use of the Service, it is necessary to create an account
            (“Account”). The Service is intended solely for persons who are 18
            or older. Any access to or use of the Service by anyone under 18 is
            expressly prohibited. By accessing or using the Service you
            represent and warrant that you are 18 or older.
          </Block>
          <TermsPolicyHeader sub_head="4. Accounts" />
          <Block>
            1. When you create an account with us, you must provide us with
            information that is accurate, complete, and current at all times.
            Failure to do so constitutes a breach of the Terms, which may result
            in immediate termination of your account on our Service. To create
            your Account you will have to provide your full name with a current
            email address and credit card details (only Advertisers).
          </Block>
          <Block>
            2. You are responsible for safeguarding the password that you use to
            access the Service and for any activities or actions under your
            password, whether your password is with our Service or a third-party
            service.
          </Block>
          <Block>
            3. You agree not to disclose your password to any third party. You
            must notify us immediately upon becoming aware of any breach of
            security or unauthorized use of your account.
          </Block>
          <Block>
            4. You may not use as a username the name of another person or
            entity or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than you without appropriate authorization, or a name that is
            otherwise offensive, vulgar or obscene.
          </Block>
          <Block>
            5. Your Account is strictly personal and may not be used by anyone
            else. You may not impersonate any other person in any registration
            whether or not that other person is a user of the Service.
          </Block>
          <Block>
            6. If you manage more than one business entity, you must create a
            new Account for each and every different business entity. If you
            fail to do so, we reserve the right at our discretion to block,
            cancel or remove an Account of any person who in our opinion
            possesses more than one Account at any time.
          </Block>
          <Block>
            7. We reserve the right to terminate your registration immediately
            without notice if in our opinion you have breached these Terms.
          </Block>
          <Block>
            8. You may not use the Service for any illegal or unauthorized
            purpose. You must not, in the use of the Service, violate any laws
            in your jurisdiction (including but not limited to copyright laws).
          </Block>
          <TermsPolicyHeader sub_head="5. Fees" />
          <Block>
            1. A valid payment method, including a credit card or PayPal, is
            required for paying accounts. If neither of these is present on the
            account, the account may be closed
          </Block>
          <Block>
            2. Metricks, in its sole discretion and at any time, may modify the
            Subscription fees for the Subscriptions. Any Subscription fee change
            will become effective at the end of the then-current Billing Cycle.
            You hereby agree to keep such valid credit card and/or bank account
            in effect with sufficient credit limit to enable Metricks to charge
            the Subscription Fees every month, quarter or another period of each
            Term, as applicable, and not to challenge such charges or to request
            reversal of such charges.
          </Block>
          <TermsPolicyHeader sub_head="6. Taxes" />
          <Block>
            All fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities, and you shall be responsible for payment of all
            such taxes, levies, or duties. Metricks has no responsibility or
            legal power to ensure the payment of the Advertiser and accepts no
            responsibility or liability in the event that the Affiliate fails to
            arrange or collect payment from the Advertiser. Metricks may add any
            such taxes to the applicable Fees.
          </Block>
          <TermsPolicyHeader sub_head="7. Refunds" />
          <Block>
            The Service is billed in advance on a monthly or yearly basis and is
            non-refundable. There will be no refunds or credits for partial
            months of service, upgrade/downgrade refunds, or refunds for months
            unused with an open account.
          </Block>
          <TermsPolicyHeader sub_head="8. Fee Changes" />
          <Block>
            1. Metricks, in its sole discretion and at any time, may modify the
            Subscription fees for the Subscriptions. Any Subscription fee change
            will become effective at the end of the then-current Billing Cycle.
            Metricks will provide you with reasonable prior notice of any change
            in Subscription fees to give you an opportunity to terminate your
            Subscription before such change becomes effective. Your continued
            use of the Service after the Subscription fee change comes into
            effect constitutes your agreement to pay the modified Subscription
            fee amount.
          </Block>
          <Block>
            2. Metricks shall not be liable to you or to any third party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </Block>

          <TermsPolicyHeader sub_head="9. Subscription and Tracking" />
          <Block>
            1. If you choose to upgrade or downgrade your Service, your credit
            card will automatically be charged the new rate on your next billing
            cycle.
          </Block>
          <Block>
            2. Downgrading your Service may cause the loss of content, features,
            or capacity of your Account. Metricks does not accept any liability
            for such loss.
          </Block>
          <Block>
            3. Downgrading your Service to a non-white label level will cause
            all Affiliates who registered while the white label Service was
            activated, to be deactivated
          </Block>
          <Block>
            4. Every Service level has its own number of tracking events
            (“Tracking Events”). Whenever the number of Tracking Events, as
            agreed upon and limited in the relevant Service level, is exceeded
            during the Subscription Period, we will calculate the price for the
            surplus Tracking Events in accordance with the overage fees listed
            on the pricing page as part of Service level. In such a case, the
            extra costs of the surplus Tracking Events will be added to the
            relevant monthly fee.
          </Block>
          <TermsPolicyHeader sub_head="10. Cancellation and Termination" />
          <Block>
            1. We may terminate or suspend your account immediately, without
            prior notice or liability, for any reason whatsoever, including
            without limitation if you breach the Terms. Upon termination, your
            right to use the Service will immediately cease. If you wish to
            terminate your account, you may simply discontinue using the
            Service.
          </Block>
          <Block>
            2. We may terminate or suspend your account immediately, without
            prior notice or liability, for any reason whatsoever, including
            without limitation if you breach the Terms. Upon termination, your
            right to use the Service will immediately cease. If you wish to
            terminate your account, you may simply discontinue using the
            Service.
          </Block>
          <Block>
            3. If we exercise our discretion under these Terms to do so, any or
            all of the following can occur with or without prior notice or
            explanation to you: (a) your Account will be deactivated or
            suspended, your password will be disabled, and you will not be able
            to access the Service, or receive assistance from our customer
            service.
          </Block>
          <Block>
            4. You may cancel your Account at any time from the Subscription &
            Billing page. You are solely responsible for properly cancelling
            your account. Please note that if your Account is cancelled, we do
            not have an obligation to delete or return to you any content.
          </Block>
          <Block>
            5. All of your content (text and files) may be immediately deleted
            from the Service upon cancellation. This information cannot be
            recovered once your account is cancelled.
          </Block>
          <Block>
            6. If you cancel the Service before the end of your current Service
            Period, your cancellation will take effect immediately and you will
            not be charged again.
          </Block>
          <Block>
            7. Metricks, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service, or any other service from Metricks, for any reason
            at any time. Such termination of the Service will result in the
            deactivation or deletion of your Account or your access to your
            Account, and the forfeiture and relinquishment of all content in
            your Account. Metricks reserves the right to refuse service to
            anyone for any reason at any time
          </Block>
          <TermsPolicyHeader sub_head="11. Intellectual Property" />
          <Block>
            The Service and its original content, features and functionality are
            and will remain the exclusive property of Metricks Inc and its
            licensors. The Service is protected by copyright, trademark, and
            other laws of both the United States of America and foreign
            countries. Our trademarks and trade dress may not be used in
            connection with any product or service without the prior written
            consent of Metricks.
          </Block>
          <TermsPolicyHeader sub_head="12. Disclaimer" />
          <Block>
            1. all the materials on Metricks’s Website are provided "as is".
            Metricks makes no warranties, may it be expressed or implied,
            therefore negates all other warranties. Furthermore, Metricks does
            not make any representations concerning the accuracy or reliability
            of the use of the materials on its Website or otherwise relating to
            such materials or any sites linked to this Website
          </Block>
          <Block>
            2. Metricks, its subsidiaries, affiliates, and its licensors do not
            warrant that a) the Service will function uninterrupted, secure or
            available at any particular time or location; b) any errors or
            defects will be corrected; c) the Service is free of viruses or
            other harmful components, or d) the results of using the Service
            will meet your requirements.
          </Block>
          <Block>
            3. We do not screen user-generated content or information on the
            Service and we cannot give any assurance as to its accuracy or
            completeness. Users of the Service are expressly asked not to
            publish any defamatory, misleading or offensive content or any
            content which infringes any other persons intellectual property
            rights (eg. copyright). Any such content is contrary to our policy
            we do not accept liability in respect of such content, and the user
            responsible will be personally liable for any damages or other
            liability arising and you agree to indemnify us in relation to any
            liability we may suffer as a result of any such content.
          </Block>
          <Block>
            4. Metricks has the right in its sole discretion to refuse or remove
            any Content that is available via the Service.
          </Block>
          <Block>
            5. The Service and this website or any portion of the Service or the
            website may not be reproduced, duplicated, copied, sold, resold,
            visited, or otherwise exploited for any commercial purpose without
            our express written consent. You may not systematically extract
            and/or re-utilize parts of the contents of the service without our
            express written consent. in particular, you may not utilise any data
            mining, robots, or similar data gathering and extraction tools to
            extract (whether once or many times) for re-utilization of any
            substantial parts of the service without our express written
            consentilisation of any substantial parts of the Service without our
            express written consent.
          </Block>
          <Block>
            All content posted on the Service must comply with the relevant
            copyright law. We claim no intellectual property rights over the
            material you provide to the Service. Your profile and materials
            uploaded remain yours. However, if you or anyone else with access to
            your account makes any content public, you agree to allow others to
            view and share your content.
          </Block>
          <TermsPolicyHeader sub_head="13. Limitation and Liability" />
          <Block>
            1. In no event shall Metricks, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the Service; (ii) any conduct or
            content of any third party on the Service; (iii) any content
            obtained from the Service; (iv) unauthorized access, use or
            alteration of your transmissions or content, whether based on
            warranty, contract, tort (including negligence) or any other legal
            theory, whether or not we have been informed of the possibility of
            such damage, and even if a remedy set forth herein is found to have
            failed of its essential purpose.
          </Block>
          <Block>
            2. Your access to the Service may be occasionally restricted to
            allow for repairs, maintenance or the introduction of new facilities
            or services. We will attempt to restore the Service as soon as we
            reasonably can.
          </Block>
          <Block>
            3. From time to time it may be necessary to suspend access to the
            Service for a period of time and any such interruptions shall not
            constitute a breach by us of these Terms.
          </Block>
          <Block>
            4. The User agrees that the above exclusions of liability are
            reasonable in all the circumstances, especially in light of the fact
            that our Service includes only the provision of the Service and
            responsibility for any payment to Affiliates lies solely with the
            Advertisers.
          </Block>
          <TermsPolicyHeader sub_head="14. Publicity" />
          <Block>
            In further consideration of the rights granted hereunder to the
            User, User agrees to permit Metricks to use User’s name and
            trademarks and service marks to identify User as Metricks’s customer
            on the Website, in Metricks’s marketing materials and in other sales
            and marketing activities, provided that User does not notify
            Metricks in writing of its revocation of such permission. User
            agrees to cooperate with Metricks in reasonable publicity efforts
            involving the System, such as, for example, media releases and
            marketing materials in accordance with Metricks's reasonable
            request.
          </Block>
          <Header>Your Privacy</Header>
          <LinkBlock>Please read our privacy policy</LinkBlock>
          <TermsPolicyHeader sub_head="15. General" />
          <Block>
            1. Your use of the Service is at your sole risk. The Service is
            provided on an “as is” and “as available” basis.
          </Block>
          <Block>
            2. You understand that Metricks uses third party vendors and hosting
            partners to provide the necessary hardware, software, networking,
            storage, and related technology required to run the Service.
          </Block>
          <Block>
            3. You must not modify, adapt or hack the Service or modify another
            website so as to falsely imply that it is associated with the
            Service or Metricks.
          </Block>
          <Block>
            4. You may not reverse engineer or reuse source code that is in
            public view. This includes any and all javascript.
          </Block>
          <Block>
            5. Verbal, physical, written or other abuse (including threats of
            abuse or retribution) of any Metricks customer, employee, member, or
            officer will result in immediate Account termination.
          </Block>
          <Block>
            6. You understand that the technical processing and transmission of
            the Service, including your content, may be transferred unencrypted
            and involve (a) transmissions over various networks; and (b) changes
            to conform and adapt to the technical requirements of connecting
            networks or devices.
          </Block>
          <Block>
            7. You must not upload, post, host, or transmit unsolicited emails
            or “spam” messages.
          </Block>
          <Block>
            8. You must not transmit any worms or viruses or any code of a
            destructive nature.
          </Block>
          <Block>
            9. If your bandwidth usage exceeds the monthly limit or
            significantly exceeds the average bandwidth usage, we reserve the
            right to immediately suspend your account or throttle your file
            hosting until you can reduce your bandwidth consumption or upgrade
            your account.
          </Block>
          <Block>
            10. We do not warrant that
            <br /> (i) the Service will meet your specific requirements, <br />
            (ii) the Service will be uninterrupted, timely, secure, or
            error-free,
            <br /> (iii) the results that may be obtained from the use of the
            Service will be accurate or reliable, <br />
            (iv) the quality of any products, the Service, information, or other
            material purchased or obtained by you through the Service will meet
            your expectations.
          </Block>
          <Block>
            11. The failure of Metricks to exercise or enforce any right or
            provision of these Terms shall not constitute a waiver of such right
            or provision. These Terms constitute the entire agreement between
            you and Metricks and govern your use of the Service, superseding any
            prior agreements between you and Metricks (including, but not
            limited to, any prior versions of these Terms
          </Block>
          <Block>
            12. Neither party will be liable to the other or be deemed to be in
            breach of these Terms by reason of any delay in performing, or any
            failure to perform, any of their obligations if the delay or failure
            was due to any event of force majeure beyond their reasonable
            control such as severe weather, subsidence, power or other utility
            cut-offs, burglary, natural disaster, strikes, governmental action,
            terrorism, war or civil unrest.
          </Block>
          <Block>
            13. The parties agree that these Terms are fair and reasonable in
            all the circumstances. However, if any provision of these Terms is
            held not to be valid by a court of competent jurisdiction but would
            be valid if part of the wording were deleted, then such provision
            shall apply with such deletions as may be necessary to make it
            valid. If any of the provisions in these terms are held not to be
            valid the remaining provisions of these terms shall remain in full
            force and effect.
          </Block>
          <Block>
            14. If you breach these terms and conditions and we decide to take
            no action or neglect to do so, then we will still be entitled to
            take action and enforce our rights and remedies for any other
            breach.
          </Block>
          <Block>
            15. We may make changes to the format of the Service or the content
            of the Service at any time without notice.
          </Block>
          <Block>
            16. Advertisers will be solely responsible for the registration of
            Affiliates. Such registration can be executed for instance through a
            branded registration page, as a result of which Affiliates might
            receive transactional emails from Metricks.
          </Block>
          <Block>
            17. The Advertiser will impose these general terms and conditions
            unconditionally upon all its Affiliates and is and will be on all
            circumstances liable for any infringement on these terms by any
            Affiliate and/or any claim brought forward by one or more
            Affiliates.
          </Block>
          <Block>
            18. Affiliates who registered before a white label was activated by
            the Advertiser, will not be able to use the custom subdomain or the
            domain alias to log in on the affiliate portal. As a result of this,
            they will not be able to see the custom branding. They will be able
            however to promote the programs of the Advertiser and all Affiliate
            links will remain active.
          </Block>
          <Block>
            19. Once a domain alias has been created, without any costs, this
            alias can be changed during the first 7 days. After this term of 7
            days, Metricks will be entitled to charge extra costs for such
            change.
          </Block>
          <Block>
            20. Once a domain alias has been created, without any costs, this
            alias can be changed during the first 7 days. After this term of 7
            days, Metricks will be entitled to charge extra costs for such
            change.
          </Block>
          <TermsPolicyHeader sub_head="16. Acceptance" />
          <Block>
            1. The User acknowledges having read this agreement, understands it
            and agrees to be bound by its terms. The person accepting this
            agreement on the User’s behalf through Metricks’s online
            registration process or by means of a User order represents that
            he/she has the authority to bind User to these terms and conditions.
          </Block>
          <Block>
            2. The User further acknowledges that this Agreement does not have
            to be signed in order to be binding. User will have indicated its
            assent to the terms of the Agreement by clicking on the "Accepted
            and Agreed To" (or similar button) that is presented to the user at
            the time of its opening of an online account with Metricks and
            submitting an order for the Cloud Services through Metricks’s online
            registration process on the Website
          </Block>
          <TermsPolicyHeader sub_head="17. Terms and Service Modification" />
          <Block>
            1. Metricks may revise these Terms of Service for its Website at any
            time without prior notice. By using this Website, you are agreeing
            to be bound by the current version of these Terms and Conditions of
            Use. If a revision is material, we will try to provide at least 30
            days notice prior to any new terms taking effect. What constitutes a
            material change will be determined at our sole discretion.
          </Block>
          <Block>
            2. User’s continued use of the Services following the effective date
            of an amendment will confirm User’s consent thereto. This Agreement
            may not be amended in any other way except through a written
            agreement by authorized representatives of each party. Metricks may
            revise the Privacy Policy and any other Metricks policy at any time
            by posting a new version of either at the Website, and such new
            version will become effective on the date it is posted.
          </Block>
          <TermsPolicyHeader sub_head="Contact Us" />
          <Block>
            If you have any questions about these Terms, please contact us over
            live chat or email support@metricks..io
          </Block>
          <Footer />
        </Content>
      </TermComponent>
    </>
  );
};

export default Terms;
