import React from "react";
import { Footer, Blank, Wrapper } from "./index";
import styled from "styled-components";

const Component = styled.div`
  background: #fff;
  top: 0;
  height: 100%;
  width: 100%;
  margin-top: 80px;
  z-index: 0;
  position: relative;
`;
const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
`;
const Content = styled.div`
  padding: 0 20px;
  background-color: #210045;
  min-height: calc(100vh - 100px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
  .bg-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 0;
  }
  .bg-image.blue {
    top: 250px;
    right: 12%;
    width: 80px;
    height: 80px;
    opacity: 0.5;
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .bg-image.orange {
    width: 150px;
    height: 150px;
    bottom: 15%;
    left: 30%;
    z-index: 0;
    svg {
      width: 150px;
      height: 150px;
    }
  }
  .bg-image.purple {
    top: 150px;
    left: 12%;
    width: 140px;
    height: 140px;
    svg {
      width: 140px;
      height: 140px;
    }
  }
  @media (max-width: 768px) and (min-width: 500px) {
    padding: 0 10px;
    .bg-image.blue {
      width: 60px;
      height: 60px;
    }
    .bg-image.orange {
      width: 120px;
      height: 120px;
    }
    .bg-image.purple {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 500px) {
    padding: 0 10px;
    .bg-image.blue {
      width: 60px;
      height: 60px;
    }
    .bg-image.orange {
      width: 120px;
      height: 120px;
    }
    .bg-image.purple {
      width: 100px;
      height: 100px;
    }
  }
`;
const Home = () => {
  document.documentElement.translate = "yes";
  return (
    <>
      <Component className="App">
        <Content>
          <Background>
            <span class="bg-image purple">
              <svg viewBox="0 0 68.76 68.76">
                <defs>
                  <linearGradient
                    id="purple"
                    x1="34.17"
                    y1="59.32"
                    x2="34.65"
                    y2="2.6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#19073b" />
                    <stop offset="0.14" stopColor="#370e4e" />
                    <stop offset="0.49" stopColor="#7f1e7b" />
                  </linearGradient>
                </defs>
                <title>pink</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle
                      fill="url(#purple)"
                      cx="34.38"
                      cy="34.38"
                      r="34.38"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span className="bg-image blue">
              <svg viewBox="0 0 68.76 68.76">
                <defs>
                  <linearGradient
                    id="blue"
                    x1="34.17"
                    y1="59.32"
                    x2="34.65"
                    y2="2.6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#19073b" />
                    <stop offset="0.49" stopColor="#213f72" />
                  </linearGradient>
                </defs>
                <title>blue</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle fill="url(#blue)" cx="34.38" cy="34.38" r="34.38" />
                  </g>
                </g>
              </svg>
            </span>
            <span class="bg-image orange">
              <svg viewBox="0 0 68.76 68.76">
                <defs>
                  <linearGradient
                    id="orange"
                    x1="34.17"
                    y1="59.32"
                    x2="34.65"
                    y2="2.6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#19073b" />
                    <stop offset="0.14" stopColor="#371836" />
                    <stop offset="0.49" stopColor="#7f4129" />
                  </linearGradient>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle
                      fill="url(#orange)"
                      cx="34.38"
                      cy="34.38"
                      r="34.38"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </Background>
          <Blank />
          <Wrapper />
          <Footer isCircle />
        </Content>
      </Component>
    </>
  );
};

export default Home;
