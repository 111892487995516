import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Swal from "sweetalert2";
const Component = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .input-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    @media (max-width: 400px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;
const BareInput = styled.input`
  background: transparent;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #f4f4f4;
  outline: none;
  color: #f4f4f4;
  width: 250px;
  margin: 0 10px;
  @media (max-width: 400px) {
    width: 95%;
    margin: 10px 0;
  }
`;
const EmailInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 700px;
  margin: 20px auto;
  margin-bottom: 80px;
  border-radius: 50px;
  background: #fff;
  .input {
    width: 60%;
    border-radius: 50px;

    height: 65px;
    border: 1px solid #ffffff;
    background: #fff;
    padding-left: 30px;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.2px;
    color: #868e96;
    @media (max-width: 768px) and (min-width: 500px) {
      width: calc(100% - 70px);
      font-size: 16px;
    }
    @media (max-width: 500px) {
      font-size: 14.5px;
      width: calc(100% - 70px);
    }
    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
    &::-webkit-input-placeholder {
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.2px;
      color: #868e96;
    }
  }
  .button {
    height: 60px;
    width: 39%;
    margin: auto;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
    background: #271ac1;
    font-weight: 600;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
    text-transform: capitalize;
    color: #fff;
    border: none;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.2px;
    .sm {
      display: none;
    }
    @media (max-width: 768px) and (min-width: 500px) {
      font-size: 15px;
      width: 60px;
      .lg {
        display: none;
      }
      .sm {
        display: block;
      }
    }
    @media (max-width: 500px) {
      font-size: 14px;
      width: 60px;
      .lg {
        display: none;
      }
      .sm {
        display: block;
      }
    }
  }
`;
const host = "https://peddle-api.herokuapp.com/api/v1/";
// const host = "http://localhost:5000/api/v1/";
const sendMailApi = "send-mail?email=";

const Form = ({ autoFocus }) => {
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const addToSubscribers = () => {
    // window.fbq('track', 'Lead');
    setLoading(true);
    if (email !== "" || firstname !== "" || lastname !== "") {
      axios
        .get(
          `${host}${sendMailApi}${email}&firstname=${firstname}&lastname=${lastname}`
        )
        .then((response) => {
          Swal.fire({
            title: "Great!",
            text: "You have been added to our wait list!",
            icon: "success",
            confirmButtonText: "Okay",
          }).then(() => window.location.reload(false));
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            title: "Error!",
            text: "Please, enter a valid email that has not been used before",
            icon: "error",
            confirmButtonText: "Okay",
          });
        })
        .then(() => setLoading(false));
    } else {
      Swal.fire({
        title: "Error!",
        text: "Please, enter all fields so we can can reach out to you when we are launched.",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };
  const inputRef = useRef();
  useEffect(() => {
    console.log(window);
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  return (
    <Component>
      <div className="input-container">
        <BareInput
          placeholder="First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <BareInput
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <EmailInput>
        <input
          className="input"
          placeholder="email address..."
          ref={inputRef}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="button"
          onClick={addToSubscribers}
          disabled={loading}
          id="submitButton"
        >
          <span className="lg">
            {loading ? "Loading" : "Join our waiting list"}
          </span>
          <small className="sm">{loading ? "Loading" : "Submit"}</small>
        </button>
      </EmailInput>
    </Component>
  );
};

export default Form;
