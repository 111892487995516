import React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { showModalAtom, showSidebarAtom } from "../atoms";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Navbar = () => {
  const setShowModal = useSetRecoilState(showModalAtom);
  const [showSidebar, setShowSidebar] = useRecoilState(showSidebarAtom);
  const history = useHistory();
  function handleClick(path) {
    history.push(path);
  }
  return (
    <Component>
      <div className="main">
        <div
          className="left"
          onClick={() => {
            setShowSidebar(false);
            handleClick("/");
          }}
        >
          <span>
            <img src="/assets/metricks-white.svg" alt="" />
          </span>
        </div>
        <div
          className="hamburger"
          onClick={() => {
            setShowSidebar(!showSidebar);
          }}
        >
          <svg viewBox="0 0 512 512">
            <g>
              <g>
                <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
			C512,404.954,503.046,396,492,396z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="right">
          <button
            className="button-dark"
            onClick={() => {
              setShowSidebar(false);
              handleClick("/about");
            }}
          >
            About
          </button>
          <button
            className="button-dark"
            onClick={() => {
              window.open("https://blog.metricks.io", "_blank");
            }}
          >
            Resources
          </button>
          <button className="button-light" onClick={() => setShowModal(true)}>
            Contact Us
          </button>
        </div>
      </div>
    </Component>
  );
};

const Component = styled.div`
  width: 100%;
  position: fixed;
  background: #210045;
  padding: 0 10px;
  top: 0;
  width: 100%;
  z-index: 1;
  .main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
  .hamburger {
    cursor: pointer;
    display: none;
    background: transparent;
    border: none;
    height: fit-content;
    margin-right: 10px;
    @media (max-width: 768px) and (min-width: 500px) {
      display: flex;
    }
    @media (max-width: 500px) {
      display: flex;
    }
    svg {
      width: 25px;
      fill: #fff;
    }
  }
  .left {
    span {
      margin-left: 20px;
      color: #ffffff;
      cursor: pointer;
      height: 50px;
      width: 100px;
      img {
        width: 80px;
        height: 100%;
      }
    }
    @media (max-width: 768px) and (min-width: 500px) {
      span {
        font-size: 20px;
      }
    }
    @media (max-width: 500px) {
      span {
        font-size: 20px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 768px) and (min-width: 500px) {
      display: none;
    }
    @media (max-width: 500px) {
      display: none;
    }
    .button-dark {
      border: none;
      cursor: pointer;
      margin: 0 20px;
      font-size: 14px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      background: none;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 1;
    }
    .button-light {
      font-weight: 800;
      margin: 0 20px;
      font-size: 14px;
      line-height: 19px;
      width: 152px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      box-sizing: border-box;
      border-radius: 20px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #09090991;
      border-radius: 50px;
      text-transform: uppercase;
      opacity: 1;
    }
  }
`;

export default Navbar;
