import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

const Component = styled.div`
  display: flex;
  z-index: 1;
  height: 300px;
  overflow: hidden;
  justify-content: space-between;
  padding: 20px 10px;
  max-width: 1200px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .background {
    width: 100%;
    position: absolute;
  }

  .circle {
    border: 2px solid #fff;
    width: 1200px;
    opacity: 0.4;
    background: #fff;
    margin: 0 auto;
    height: 1200px;
    border-radius: 50%;
    backdrop-filter: blur(5px);
  }
  .contents {
    height: 300px;
    margin-top: 50px;
    z-index: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .socials {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    & > a {
      width: 100%;
      height: 100%;
      color: #fff;
      svg {
        height: 30px;
      }
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    font-size: 13px;
    opacity: 0.7;
  }
  .center {
    width: fit-content;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 15px;
    width: 100%;
    max-width: 350px;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
    display: flex;
    a {
      margin: 0 20px;
      text-decoration: none;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      opacity: 0.7;
      @media (max-width: 500px) {
        font-size: 13px;
      }
      line-height: 31px;
      color: #ffffff;
      flex-wrap: nowrap;
      white-space: nowrap;
      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }
  .icon-wrap {
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      margin: 0 15px;
    }
    width: 30px;
    height: 30px;
    margin: 0 5px;
    align-content: center;
    margin: 0 20px;
    border-radius: 50%;
    svg {
      height: 30px;
      width: 30px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

const Footer = ({ isCircle }) => {
  return (
    <Component>
      {isCircle && (
        <div className="background">
          <div className="circle"></div>
        </div>
      )}
      <div className="contents">
        <div className="socials">
          <div className="icon-wrap">
            <a href="https://www.linkedin.com/company/metricks-io" id="linkedin">
              <svg width="10" height="11" viewBox="0 0 21 22">
                <path
                  d="M20.1351 0.879837H1.21039C0.755268 0.879837 0.387573 1.24753 0.387573 1.70265V20.6273C0.387573 21.0825 0.755268 21.4501 1.21039 21.4501H20.1351C20.5902 21.4501 20.9579 21.0825 20.9579 20.6273V1.70265C20.9579 1.24753 20.5902 0.879837 20.1351 0.879837ZM6.48924 18.4083H3.43712V8.59113H6.48924V18.4083ZM4.96447 7.24892C4.61458 7.24892 4.27256 7.14517 3.98164 6.95078C3.69072 6.7564 3.46398 6.48011 3.33008 6.15686C3.19619 5.83361 3.16115 5.47791 3.22941 5.13475C3.29767 4.79159 3.46616 4.47637 3.71356 4.22897C3.96097 3.98156 4.27618 3.81308 4.61934 3.74482C4.96251 3.67656 5.3182 3.71159 5.64145 3.84549C5.9647 3.97938 6.24099 4.20613 6.43538 4.49704C6.62976 4.78796 6.73351 5.12999 6.73351 5.47987C6.73094 6.45696 5.93899 7.24892 4.96447 7.24892ZM17.9161 18.4083H14.8665V13.6334C14.8665 12.4943 14.8459 11.0313 13.28 11.0313C11.6935 11.0313 11.4493 12.2706 11.4493 13.5511V18.4083H8.40228V8.59113H11.3284V9.93335H11.3695C11.7758 9.16196 12.7709 8.34686 14.2571 8.34686C17.3478 8.34686 17.9161 10.3807 17.9161 13.024V18.4083Z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrap">
            <a id="facebook" href="https://facebook.com/metricks.io">
              <svg width="10" height="11" viewBox="0 0 21 22">
                <path
                  d="M19.881 0.879837H0.956357C0.501239 0.879837 0.133545 1.24753 0.133545 1.70265V20.6273C0.133545 21.0825 0.501239 21.4501 0.956357 21.4501H19.881C20.3362 21.4501 20.7039 21.0825 20.7039 20.6273V1.70265C20.7039 1.24753 20.3362 0.879837 19.881 0.879837ZM17.5052 6.8838H15.8621C14.5739 6.8838 14.3245 7.49576 14.3245 8.39571V10.3782H17.3998L16.9986 13.4817H14.3245V21.4501H11.1181V13.4843H8.43624V10.3782H11.1181V8.08973C11.1181 5.43359 12.7406 3.98595 15.1113 3.98595C16.2478 3.98595 17.2223 4.07081 17.5077 4.10938V6.8838H17.5052Z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrap">
            <a id="twitter" href="https://www.twitter.com/metricks_io">
              <svg width="10" height="11" viewBox="0 0 22 18">
                <path
                  d="M21.8621 2.53884C21.0753 2.87825 20.2191 3.12252 19.3371 3.21766C20.2528 2.67367 20.9385 1.81425 21.2656 0.800647C20.4063 1.31179 19.465 1.67022 18.4834 1.86002C18.0732 1.42142 17.577 1.07202 17.0258 0.833579C16.4746 0.595137 15.8802 0.472767 15.2796 0.474094C12.8498 0.474094 10.8956 2.4437 10.8956 4.86071C10.8956 5.20012 10.9367 5.53953 11.0036 5.86609C7.3652 5.67581 4.12023 3.93762 1.96292 1.27634C1.56984 1.94774 1.36385 2.71221 1.36638 3.49022C1.36638 5.01242 2.14034 6.35463 3.32056 7.14402C2.62504 7.11663 1.9458 6.92545 1.3381 6.58605V6.64004C1.3381 8.77164 2.84487 10.5381 4.85305 10.9444C4.47599 11.0423 4.08811 11.0924 3.69854 11.0935C3.41313 11.0935 3.14314 11.0652 2.87059 11.0267C3.42598 12.7649 5.04332 14.0274 6.96922 14.0685C5.46244 15.2487 3.57512 15.943 1.5258 15.943C1.15811 15.943 0.818697 15.9301 0.466431 15.889C2.41032 17.136 4.71677 17.856 7.20064 17.856C15.2642 17.856 19.6765 11.1758 19.6765 5.37754C19.6765 5.18727 19.6765 4.99699 19.6637 4.80672C20.5173 4.18189 21.2656 3.40793 21.8621 2.53884Z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrap">
            <a href="https://www.youtube.com/channel/UC8FRSb8AmMk0YFDt_M7Ypmw" id="youtube">
              <svg
                width="10"
                height="11"
                viewBox="0 0 27 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.9563 3.41435C25.659 2.38967 24.7875 1.58192 23.6825 1.30601C21.6636 0.793884 13.588 0.793884 13.588 0.793884C13.588 0.793884 5.51267 0.793884 3.49381 1.28659C2.40998 1.56221 1.5173 2.38982 1.22001 3.41435C0.688721 5.28591 0.688721 9.16727 0.688721 9.16727C0.688721 9.16727 0.688721 13.0682 1.22001 14.9202C1.51762 15.9447 2.38872 16.7525 3.49397 17.0284C5.53393 17.5407 13.5883 17.5407 13.5883 17.5407C13.5883 17.5407 21.6636 17.5407 23.6825 17.048C24.7877 16.7722 25.659 15.9644 25.9566 14.9399C26.4877 13.0682 26.4877 9.18698 26.4877 9.18698C26.4877 9.18698 26.509 5.28591 25.9563 3.41435ZM11.0169 12.753V5.58153L17.7321 9.16727L11.0169 12.753Z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrap">
            <a href="https://www.instagram.com/metricks.io" id="instagram">
              <svg
                width="10"
                height="11"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    d="M12.2976 10.9022C12.2976 11.904 11.4853 12.7162 10.4836 12.7162C9.48176 12.7162 8.66956 11.904 8.66956 10.9022C8.66956 9.90037 9.48176 9.08817 10.4836 9.08817C11.4853 9.08817 12.2976 9.90037 12.2976 10.9022Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M13.5472 5.41986H7.41988C6.08616 5.41986 5.00122 6.5048 5.00122 7.83852V13.9658C5.00122 15.2995 6.08616 16.3845 7.41988 16.3845H13.5472C14.8809 16.3845 15.9658 15.2995 15.9658 13.9658V7.83852C15.9658 6.5048 14.8809 5.41986 13.5472 5.41986ZM10.4835 13.9255C8.81644 13.9255 7.4602 12.5692 7.4602 10.9022C7.4602 9.23508 8.81644 7.87884 10.4835 7.87884C12.1506 7.87884 13.5069 9.23508 13.5069 10.9022C13.5069 12.5692 12.1506 13.9255 10.4835 13.9255ZM13.9503 8.04008C13.6163 8.04008 13.3456 7.7694 13.3456 7.43541C13.3456 7.10143 13.6163 6.83075 13.9503 6.83075C14.2843 6.83075 14.5549 7.10143 14.5549 7.43541C14.5549 7.7694 14.2843 8.04008 13.9503 8.04008Z"
                    fill="#ffffff"
                  />
                  <path
                    d="M15.3612 0.58255H5.60594C2.60528 0.58255 0.16394 3.02389 0.16394 6.02454V15.7798C0.16394 18.7805 2.60528 21.2218 5.60594 21.2218H15.3612C18.3619 21.2218 20.8032 18.7805 20.8032 15.7798V6.02454C20.8032 3.02389 18.3619 0.58255 15.3612 0.58255ZM17.1752 13.9658C17.1752 15.9663 15.5477 17.5938 13.5472 17.5938H7.41993C5.4195 17.5938 3.79194 15.9663 3.79194 13.9658V7.83854C3.79194 5.83811 5.4195 4.21055 7.41993 4.21055H13.5472C15.5477 4.21055 17.1752 5.83811 17.1752 7.83854V13.9658Z"
                    fill="#ffffff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="20.6393"
                      height="20.6393"
                      fill="white"
                      transform="translate(0.16394 0.58255)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div className="center">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div className="copyright">
          © Copyright 2021 Peddle Technologies. All Rights Reserved.
        </div>
      </div>
    </Component>
  );
};

export default Footer;
