import React from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";
import { AnimatePresence, motion } from "framer-motion";
import { showModalAtom, showSidebarAtom } from "../atoms";
import styled from "styled-components";

const Component = styled.div`
  display: none;
  z-index: 1000;
  height: fit-content;
  @media (max-width: 800px) {
    display: flex;
    .mobile-nav-containers {
      position: fixed;
      background-color: #210045;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 80px;
      & > div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;
      }
      .close-button {
        width: 40px;
        display: flex;
        text-align: end;
        position: absolute;
        color: #fff;
        top: 20px;
        right: 20px;
      }
    }
    .contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: fit-content;
      margin: auto;
      background-color: #210045;
      .button {
        background: transparent;
        font-size: 30px;
        font-weight: bold;
        margin: 20px;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        padding: 16px 0;
        letter-spacing: 0.3rem;
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        transition: color 0.3s linear;
        @media (max-width: 768px) and (min-width: 500px) {
          font-size: 25px;
          text-align: center;
        }
        @media (max-width: 500px) {
          font-size: 18px;
          text-align: center;
        }

        &:hover {
          color: #ffffff;
          font-weight: bolder;
        }
      }
    }
  }
`;

const variants = {
  hidden: {
    width: "0",
    opacity: 0,
  },
  visible: {
    width: "100%",
    opacity: 1,
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
};
const MobileNav = () => {
  const setShowModal = useSetRecoilState(showModalAtom);
  const [showSidebar, setShowSidebar] = useRecoilState(showSidebarAtom);
  const history = useHistory();
  function handleClick(path) {
    history.push(path);
  }
  return (
    <AnimatePresence exitBeforeEnter>
      {showSidebar && (
        <Component>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="mobile-nav-containers"
          >
            <div>
              <div className="contents">
                <button
                  className="button"
                  onClick={() => {
                    handleClick("/about");
                    setShowSidebar(false);
                  }}
                >
                  About
                </button>
                <button
                  onClick={() => {
                    window.open("https://blog.metricks.io", "_blank");
                  }}
                  className="button"
                >
                  Resources
                </button>
                <button
                  onClick={() => {
                    setShowSidebar(false);
                    setShowModal(true);
                  }}
                  className="button"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </motion.div>
        </Component>
      )}
    </AnimatePresence>
  );
};

export default MobileNav;
