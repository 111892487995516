import React from "react";
import styled from "styled-components";

const Component = styled.div`
  width: 50%;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  .imgC{
    position: absolute;
    top: 70px;
  }
  img {
    width: 90%;
    height: 100%;
  }
  .ball{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #1D55F2;
  box-shadow: 100px 100px 60px 100px rgba(29, 85, 242, 0.46);

  }
  @media (max-width: 768px) and (min-width: 500px) {
      display: none;
      visibility: hiiden;
      width: 0%;
    }
    @media (max-width: 500px) {
            display: none;
      visibility: hiiden;
      width: 0%;

    }
`;

const Images = () => {
  return (
    <Component>
      <div className="ball">
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />
        &nbsp;
        <br />

      </div>
      <div className="imgC">
        <img className="dashboard" src="/assets/dashboard.png" alt="" />

      </div>
    </Component>
  );
};

export default Images;
