import React from "react";
import styled from "styled-components";
import { Blank } from ".";
import { useSetRecoilState } from "recoil";
import { showModalAtom } from "../atoms";
import Footer from "./Footer";
const Component = styled.div`
  background: #fff;
  top: 0;
  height: 100%;
  width: 100%;
  margin-top: 80px;
  z-index: 0;
  position: relative;
`;
const Content = styled.div`
  background-color: #210045;
  min-height: calc(100vh - 100px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

const Section1 = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  top: 30px;
  .contents {
    position: absolute;
    width: inherit;
    min-width: 260px;
    max-width: 700px;
    transform: translate(-30%, -50%);
    top: 50%;
    z-index: 2;
    left: 50%;
    font-size: 67px;
    color: #fff;
    @media (max-width: 768px) {
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
    }
    .content-header {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 20px;
      letter-spacing: 1.1px;
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      opacity: 1;
      hr {
        width: 100px;
        display: inline-block;
        background: #fff;
        margin-right: 10px;
      }
      @media (max-width: 768px) and (min-width: 400px) {
        margin-left: 20px;
        font-size: 18px;
        hr {
          width: 80px;
        }
      }
      @media (max-width: 400px) {
        margin-left: 15px;
        font-size: 16px;
        hr {
          width: 70px;
        }
      }
    }
    .content-body {
      margin-left: 150px;
      margin-top: 35px;
      text-align: left;
      font-size: 60px;
      letter-spacing: 0px;
      color: #ffffff;
      font-family: "Poppins", sans-serif;
      white-space: nowrap;
      @media (max-width: 768px) and (min-width: 400px) {
        font-size: 35px;
        margin-left: 80px;
      }
      @media (max-width: 400px) {
        font-size: 30px;
        margin-left: 40px;
      }
      @media (max-width: 300px) {
        font-size: 25px;
        margin-left: 20px;
      }
    }
  }

  .blur-clear {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: stretch;
    align-items: stretch;
    .blur {
      flex-basis: 60%;
      background: #ffffff22;
      min-height: 600px;
      height: 100%;
      padding: 50px;
      display: flex;
      justify-content: flex-end;
      backdrop-filter: blur(5px);
      overflow: hidden;
      .purple {
        width: 350px;
        min-width: 350px;
        min-height: 100%;
        background-image: linear-gradient(180deg, #862d86, transparent);
        filter: blur(5px);
      }
    }
    .clear {
      display: flex;
      flex-basis: 40%;
      height: 100%;
      min-height: 600px;
      position: relative;
      .blue {
        position: absolute;
        top: 80px;
        right: 0;
        width: 180px;
        height: 180px;
        opacity: 0.4;
        @media (max-width: 700px) {
          top: 50px;
          width: 120px;
          height: 120px;
        }
      }
      .clear-footer {
        position: absolute;
        bottom: -20px;
        left: 20px;
        line-height: 20px;
        font-weight: 100;
        letter-spacing: 2px;
        min-width: 200px;
        max-width: 400px;
        width: 100%;
        opacity: 0.7;
        color: #fff;
        font-size: 13px;
        z-index: 3;
        @media (max-width: 800px) {
          left: -100px;
        }
      }
    }
  }
`;
const Section2 = styled.div`
  margin: 80px auto;
  padding: 80px 20px;
  max-width: 1200px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 300;
  color: #fff;
  .bold {
    font-weight: bold;
    font-size: inherit;
    font-family: inherit;
  }
  @media (max-width: 768px) and (min-width: 400px) {
    font-size: 40px;
    padding: 60px 15px;
  }
  @media (max-width: 400px) {
    font-size: 30px;
    padding: 40px 10px;
  }
`;
const Section3 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  .right-content {
    flex-basis: 50%;
    background: #ffffff22;
    min-height: 400px;
    height: 100%;
    padding: 50px;
    padding-left: 100px;
    display: flex;
    justify-content: flex-start;
    backdrop-filter: blur(5px);
    overflow: hidden;
    .content {
      z-index: 2;
      position: absolute;
      top: 50%;
      padding: 20px;
      width: 100%;
      max-width: 600px;
      left: 50%;
      @media (max-width: 800px) {
        padding: 10px;
      }
      transform: translate(-50%, -50%);
      .header {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        letter-spacing: 1.1px;
        color: #ffffff;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
        opacity: 1;
        position: relative;
        .tag {
          position: absolute;
          top: -15px;
          left: 10px;
          font-size: 12px;
          font-weight: 100;
        }
        hr {
          width: 80px;
          display: inline-block;
          background: #fff;
          margin-right: 10px;
        }
      }
      .description {
        margin: 20px 0;
        width: 100%;
        font-size: 18px;
        font-weight: 200;
        letter-spacing: 1px;
        color: #fff;
        line-height: 25px;
        opacity: 0.8;
        @media (max-width: 500px) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
    .blob {
      width: 250px;
      min-width: 250px;
      min-height: 100%;
      background-image: linear-gradient(180deg, #324b7e, transparent);
      filter: blur(5px);
    }
  }
  .left-content {
    display: flex;
    flex-basis: 50%;
    height: 100%;
    min-height: 400px;
    padding: 20px;
    position: relative;
    @media (max-width: 768px) {
      padding: 10px;
    }
    .grid {
      position: absolute;
      bottom: 0px;
      left: 120px;
      display: flex;
      @media (max-width: 900px) {
        left: 20px;
      }
      svg {
        width: 80px;
        margin: 0 7.5px;
      }
    }
    .content {
      z-index: 2;
      padding: 20px;
      width: 100%;
      max-width: 600px;
      padding-left: 100px;
      @media (max-width: 900px) {
        padding-left: 10px;
      }
      @media (max-width: 800px) {
        padding: 10px;
      }
      .header {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        letter-spacing: 1.1px;
        color: #ffffff;
        text-transform: uppercase;
        font-family: "Poppins", sans-serif;
        opacity: 1;
        position: relative;
        .tag {
          position: absolute;
          top: -15px;
          left: 10px;
          font-size: 12px;
          font-weight: 100;
        }
        hr {
          width: 80px;
          display: inline-block;
          background: #fff;
          margin-right: 10px;
        }
      }
      .description {
        margin: 20px 0;
        width: 100%;
        font-size: 18px;
        font-weight: 200;
        letter-spacing: 1px;
        color: #fff;
        line-height: 25px;
        opacity: 0.8;
        @media (max-width: 500px) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    .right-content,
    .left-content {
      flex-basis: 100%;
      margin: 15px 0;
    }
  }
`;
const Section4 = styled.div`
  margin-top: 150px;
  width: 100%;
  background: #ffffff22;
  min-height: 300px;
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: flex-end;
  backdrop-filter: blur(5px);
  overflow: hidden;
  position: relative;
  .orange {
    width: 300px;
    min-width: 300px;
    min-height: 100%;
    background-image: linear-gradient(180deg, #c96b1b, transparent);
    filter: blur(5px);
  }
  .desc {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 30px;
    height: 100%;
    @media (max-width: 768px) {
      padding: 15px;
    }
    .contents {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      height: 100%;
      display: flex;
      justify-content: center;
      .question-header {
        font-size: 38px;
        font-weight: 700;
        color: #fff;
        margin: 20px 0;
      }
      .description {
        font-size: 18px;
        letter-spacing: 0.5px;
        color: #fff;
        margin: 15px 0;
        font-weight: 200;
      }
      .link {
        color: #ff00f7;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 10px 0;
        font-weight: 600;
        svg {
          margin-left: 10px;
          width: 25px;
          fill: #ff00f7;
        }
      }
      @media (max-width: 500px) {
        .question-header {
          font-size: 28px;
        }
        .description {
          font-size: 16px;
        }
        .link {
          font-size: 16px;
        }
      }
    }
  }
`;
const About = () => {
  document.documentElement.translate = "yes";
  const setShowModal = useSetRecoilState(showModalAtom);
  return (
    <>
      <Blank />
      <Component>
        <Content>
          <Section1>
            <div className="contents">
              <div className="content-header">
                <hr /> About Us
              </div>
              <div className="content-body">
                Built for SaaS
                <br /> and E-Commerce
              </div>
            </div>
            <div className="blur-clear">
              <div className="blur">
                <div className="purple">x</div>
              </div>
              <div className="clear">
                <span className="clear-footer">
                  Our tools are easy to set up and use with a user friendly
                  dashboard that enables you to set up, launch, automate and
                  manage multi-affiliate campaigns in 5 minutes.
                </span>
                <span className=" blue">
                  <svg viewBox="0 0 68.76 68.76">
                    <defs>
                      <linearGradient
                        id="blue"
                        x1="34.17"
                        y1="59.32"
                        x2="34.65"
                        y2="2.6"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#19073b" />
                        <stop offset="0.49" stopColor="#213f72" />
                      </linearGradient>
                    </defs>
                    <title>blue</title>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Objects">
                        <circle
                          fill="url(#blue)"
                          cx="34.38"
                          cy="34.38"
                          r="34.38"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </Section1>
          <Section2>
            Metricks was developed because just like you, we needed a product
            that could give us<span class="bold"> good value.</span>
          </Section2>
          <Section3>
            <div class="left-content">
              <span class="grid">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29.76 29.76"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <title>dots</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Objects">
                      <circle fill="#fff" cx="10.21" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="28.91" r="0.85" />
                    </g>
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29.76 29.76"
                >
                  <defs>
                    <style></style>
                  </defs>
                  <title>dots</title>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Objects">
                      <circle fill="#fff" cx="10.21" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="0.85" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="10.21" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="0.85" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="10.21" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="28.91" r="0.85" />
                      <circle fill="#fff" cx="19.56" cy="19.56" r="0.85" />
                      <circle fill="#fff" cx="28.91" cy="28.91" r="0.85" />
                    </g>
                  </g>
                </svg>
              </span>
              <div class="content">
                <div className="header">
                  <span className="tag">01</span>
                  <hr /> Why us?
                </div>
                <div className="description">
                  We pride ourselves in our ability to innovate and create
                  world-class tools that provide reliable and efficient
                  touchpoints between advertisers and affiliates.
                </div>
              </div>
            </div>
            <div class="right-content">
              <div class="blob"></div>
              <div class="content">
                <div className="header">
                  <span className="tag">02</span>
                  <hr /> Growing with you
                </div>
                <div className="description">
                  Leveraging the best technology, we have developed an
                  all-in-one affiliate marketing tracking software, a genius
                  affiliate campaigns, all from one dashboard.
                </div>
                <div className="description">
                  Our team of experts are constantly brainstorming, testing and
                  developing new solutions with only one thing in mind - your
                  business growth. Your success is our success and by giving you
                  the tools you need to scale, we grow as well.
                </div>
              </div>
            </div>
          </Section3>
          <Section4>
            <div className="orange">x</div>
            <div class="desc">
              <div class="contents">
                <div class="question-header">Got a Question?</div>
                <div class="description">
                  See how Metricks can help your business grow with the best
                  Affiliate Marketing Tracking Software.
                </div>
                <div class="link" onClick={() => setShowModal(true)}>
                  Contact Us
                  <svg viewBox="0 0 512 512">
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </Section4>
        </Content>
      </Component>
      <Footer />
    </>
  );
};

export default About;
