import React from "react";
import styled from "styled-components";
import { Countdown, Form } from ".";

const Component = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  color: #000000;
  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 60px;
    font-weight: bold;
    line-height: 88px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0.2px;
    color: #fff;
    @media (max-width: 768px) and (min-width: 500px) {
      font-size: 45px;
      line-height: 66px;
    }
    @media (max-width: 500px) {
      font-size: 40px;
      line-height: 44px;
    }
  }
  .extra {
    width: 100%;
    margin: 0;
    font-weight: 100;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    opacity: 0.8;
    z-index: 1;
    .bold {
      font-size: inherit;
      font-weight: 500;
    }
    @media (max-width: 768px) and (min-width: 500px) {
      font-size: 12px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;
const Header = ({ autoFocus }) => {
  return (
    <Component>
      <h1>
        Something awesome
        <br />
        is coming soon
      </h1>
      <div className="extra">
        <span className="bold">
          Providing small and medium scale enterprises with the solution they need to grow and scale their affiliate marketing.
        </span>
        <br />
        <span className="bold">Create your affiliate program today in five minutes or less.</span>
      </div>
      <Countdown />
      <Form autoFocus={autoFocus} />
    </Component>
  );
};

export default Header;
