import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 30px 0;
  font-size: 40px;
  font-weight: 600;
  text-align: start;
  font-family: "Poppins", "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 32px;
  }
  @media (max-width: 500px) {
    font-size: 26px;
  }
`;

const TermsPolicyHeader = ({ sub_head }) => {
  return <Container>{sub_head}</Container>;
};

export default TermsPolicyHeader;
