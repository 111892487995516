import React from "react";
import styled from "styled-components";

const Component = styled.div`
  width: 100%;
  height: 40px;
`;
const Blank = () => {
  return <Component />;
};

export default Blank;
