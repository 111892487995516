import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import axios from "axios";

import { motion } from "framer-motion";

const SearchStyle = styled.div`
  margin-top: 50px;
  border-radius: 0px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
  user-select: none;
  padding: 10px;
  padding-left: 30px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparent;
  }
  @media (max-width: 768px) {
    height: calc(100% - 80px);
    min-height: calc(100vh - 80px);
    overflow-y: auto;
    margin-bottom: auto;
  }
  .header {
    margin-top: 30px;
    color: white;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  .send-button {
    background: #271ac1;
    box-shadow: 0px 0px 10px #09090991;
    border-radius: 51px;
    height: 60px;
    width: fit-content;
    opacity: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    border: none;
    outline: none;
    margin: 20px 0;
    font-size: 18px;
    font-weight: 600;
    svg {
      width: 100px;
      transform: translateX(25%);
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    .input-group {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
    }
    input {
      display: flex;
      align-items: center;
      outline: none;
      appearance: none;
      border: none;
      background: #ffffff;
      height: 60px;
      width: 100%;
      border-radius: 4px;
      padding: 0 20px;
    }
    textarea {
      outline: none;
      appearance: none;
      border: none;
      background: #ffffff;
      width: 100%;
      border-radius: 4px;
      padding: 20px;
    }
    label {
      font: normal normal 300 14px/25px Poppins;
      letter-spacing: 0px;
      opacity: 0.7;
      color: #ffffff;
      margin: 10px 0;
      width: 100%;
      text-align: start;
    }
  }
`;

export const pageTransition = {
  hidden: {
    opacity: 0,
    width: "100%",
    transition: { type: "tween", duration: 0.5 },
  },
  visible: {
    opacity: 1,
    width: "100%",
    transition: { type: "tween", duration: 2, stiffness: 100 },
  },
};

const host = "https://comingsoon-be.herokuapp.com/api/v1/";
const sendMessageApi = "send-message";
const Contact = () => {
  document.documentElement.translate = "yes";
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const sendAMessage = () => {
    setLoading(true);
    console.log(email);
    if (email !== "" || firstname !== "" || lastname !== "") {
      axios
        .post(`${host}${sendMessageApi}`, {
          firstname: firstname,
          lastname: lastname,
          email: message,
          message: message,
        })
        .then((response) => {
          setLoading(false);
          toast("We have been notified. We will reach out to you soon");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            "Please, enter a valid email that has not been used before"
          );
        });
    } else {
      setLoading(false);
      toast.error(
        "Please, enter all fields so we can can reach out to you when we are launched."
      );
    }
  };
  return (
    <SearchStyle>
      <motion.div
        variants={pageTransition}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <div className="header">
          Hey, we are still in the works, <br />
          but you can send us a message!
        </div>
        <div className="form">
          <div className="input-group">
            <label for="input-name">First Name</label>
            <input
              placeholder="Enter your First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label for="input-name">Last Name</label>
            <input
              placeholder="Enter your Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label for="input-name">Email</label>
            <input
              placeholder="Enter Your Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label for="input-name">Tell us what you need help with:</label>
            <textarea rows="10" onChange={(e) => setMessage(e.target.value)}>
              Enter a topic, like " channel problem... "
            </textarea>
          </div>
        </div>
        <button className="send-button" onClick={sendAMessage}>
          {loading ? (
            <svg viewBox="0 0 100 100">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1"
                />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2"
                />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3"
                />
              </circle>
            </svg>
          ) : (
            "Send Now"
          )}
        </button>
      </motion.div>
    </SearchStyle>
  );
};

export default Contact;
