import React from "react";
import styled from "styled-components";
import { Header } from ".";

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
`;

const Wrapper = () => (
  <WrapperDiv>
    <Header />
  </WrapperDiv>
);

export default Wrapper;
