import { atom } from "recoil";

export const showModalAtom = atom({
  key: "showModal",
  default: false,
});

export const showSidebarAtom = atom({
  key: "showSidebar",
  default: false,
});
