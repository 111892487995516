import React from "react";
import styled from "styled-components";
import { Blank } from ".";
import Footer from "./Footer";
import TermsPolicyHeader from "./TermsPolicy";

const HeaderSmall = styled.div`
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0;
`;
const BlockInner = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  padding-left: 30px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0.8;
  margin: 10px 0;
  font-family: "Poppins", "Montserrat", sans-serif;
`;
const PrivacyComponent = styled.div`
  color: #fff;
  max-width: 1200px;
  min-height: calc(100vh- 100px);
  margin: 0 auto;
  background: #210045;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 17px;
    margin: 0 10px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
    margin: 0 10px;
  }
`;

const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
`;
const Header = styled.div`
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 150px;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 35px;
  }
`;
const Block = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0.8;
  margin: 10px 0;
  font-family: "Poppins", "Montserrat", sans-serif;
  @media (max-width: 768px) and (min-width: 500px) {
    font-size: 15px;
    line-height: 26px;
  }
  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
  }
`;
const Content = styled.div`
  padding: 0 20px;
  background-color: #210045;
  min-height: calc(100vh - 100px);
  min-height: 100vh;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) and (min-width: 500px) {
  }
  @media (max-width: 500px) {
    padding: 0 10px;
  }
  .bg-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 0;
  }
  .bg-image.purple {
    top: 150px;
    left: 12%;
    width: 140px;
    height: 140px;
    svg {
      width: 140px;
      height: 140px;
    }
  }
  .bg-image.dots {
    top: 250px;
    right: 12%;
    width: 100px;
    height: 100px;
    svg {
      width: 80px;
      opacity: 0.5;
      height: 80px;
    }
  }
`;
const Privacy = () => {
  document.documentElement.translate = "yes";
  return (
    <>
      <Blank />
      <PrivacyComponent>
        <Content>
          <Background>
            <span class="bg-image dots">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.76 29.76">
                <defs>
                  <style></style>
                </defs>
                <title>dots</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle fill="#fff" cx="10.21" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="0.85" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="10.21" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="0.85" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="10.21" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="28.91" r="0.85" />
                    <circle fill="#fff" cx="19.56" cy="19.56" r="0.85" />
                    <circle fill="#fff" cx="28.91" cy="28.91" r="0.85" />
                  </g>
                </g>
              </svg>
            </span>
            <span class="bg-image purple">
              <svg viewBox="0 0 68.76 68.76">
                <defs>
                  <linearGradient
                    id="purple"
                    x1="34.17"
                    y1="59.32"
                    x2="34.65"
                    y2="2.6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#19073b" />
                    <stop offset="0.14" stop-color="#370e4e" />
                    <stop offset="0.49" stop-color="#7f1e7b" />
                  </linearGradient>
                </defs>
                <title>pink</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Objects">
                    <circle
                      fill="url(#purple)"
                      cx="34.38"
                      cy="34.38"
                      r="34.38"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </Background>
          <Header>Privacy Policy</Header>
          <Block>
            1. At Metricks, accessible from http://metricks.io, one of our main
            priorities is the privacy of our users. This Privacy Policy document
            contains types of information that is collected and recorded by
            Metricks.io and how we use it.
          </Block>
          <Block>
            2. This Privacy Policy applies only to our online activities and is
            valid for visitors to our software with regards to the information
            that they shared and/or collected in Metricks.io. This policy is not
            applicable to any information collected offline or via channels
            other than this software.
          </Block>
          <Block>
            3. We only disclose your personal data to third parties that assist
            us with providing you with our services and, if you authorize us
            explicitly, to our affiliated companies for the purpose of customer
            relationship management, analytics and marketing.
          </Block>
          <Block>
            4. We only disclose your personal data to third parties that assist
            us with providing you with our services and, if you authorize us
            explicitly, to our affiliated companies for the purpose of customer
            relationship management, analytics and marketing.
          </Block>
          <TermsPolicyHeader sub_head="1. Consent" />
          <Block>
            By using our software, you hereby consent to our Privacy Policy and
            agree to its terms.
          </Block>
          <TermsPolicyHeader sub_head="2. Information we collect" />
          <Block>
            1. The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </Block>
          <Block>
            2. Unless specified otherwise, all Data requested by Metricks is
            mandatory and failure to provide this Data may make it impossible
            for Metricks to provide its services. In cases where Metricks
            specifically states that some Data is not mandatory, Users are free
            not to communicate this Data without consequences to the
            availability or the functioning of the Service..
          </Block>
          <Block>
            3. Users are responsible for any third-party Personal Data obtained,
            published or shared through this software and confirm that they have
            the third party's consent to provide the Data to the Owner.
          </Block>
          <br />
          <TermsPolicyHeader sub_head="3. Log data" />
          <Block>
            Metricks.io follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this as part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </Block>
          <TermsPolicyHeader sub_head="4. Cookies and Web Beacons" />
          <Block>
            Like any other website, Metricks.io uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </Block>
          <HeaderSmall>How we use your information</HeaderSmall>
          <Block>
            We use the information we collect in various ways, including to:
          </Block>
          <BlockInner>
            1. Provide, operate, and maintain our software
          </BlockInner>
          <BlockInner>
            2. Improve, personalize, and expand our software
          </BlockInner>
          <BlockInner>
            3. Understand and analyze how you use our software
          </BlockInner>
          <BlockInner>
            4. Develop new products, services, features, and functionality
          </BlockInner>
          <BlockInner>
            5. Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the software, and for
            marketing and promotional purposes
          </BlockInner>
          <HeaderSmall>Personal information</HeaderSmall>
          <Block>We may ask for personal information, such as your:</Block>
          <BlockInner>1. Name</BlockInner>
          <BlockInner>2. Email</BlockInner>
          <BlockInner>3. Phone/Mobile Number</BlockInner>
          <BlockInner>4. Home/Mailing address</BlockInner>
          <BlockInner>5. Payment information</BlockInner>
          <TermsPolicyHeader sub_head="5. Third-Party Privacy Policies " />
          <Block>
            Metricks.io's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt out of certain options.
          </Block>
          <Block>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </Block>
          <TermsPolicyHeader sub_head="6. Legal bases for processing " />
          <Block>
            We will process your personal information lawfully, fairly and in a
            transparent manner. We collect and process information about you
            only where we have legal bases for doing so. These legal bases
            depend on the services you use and how you use them, meaning we
            collect and use your information only where:
          </Block>
          <BlockInner>
            1. it’s necessary for the performance of a contract to which you are
            a party or to take steps at your request before entering into such a
            contract (for example, when we provide a service you request from
            us);
          </BlockInner>
          <BlockInner>
            2. it satisfies a legitimate interest (which is not overridden by
            your data protection interests), such as for research and
            development, to market and promote our services, and to protect our
            legal rights and interests;
          </BlockInner>
          <BlockInner>
            3. you give us consent to do so for a specific purpose (for example,
            you might consent to us sending you our newsletter); or
          </BlockInner>
          <BlockInner>
            4. we need to process your data to comply with a legal obligation.
          </BlockInner>
          <Block>
            We don’t keep personal information for longer than is necessary.
            While we retain this information, we will protect it within
            commercially acceptable means to prevent loss and theft, as well as
            unauthorised access, disclosure, copying, use or modification. That
            said, we advise that no method of electronic transmission or storage
            is 100% secure and cannot guarantee absolute data security. If
            necessary, we may retain your personal information for our
            compliance with a legal obligation or in order to protect your vital
            interests or the vital interests of another natural person.
          </Block>
          <TermsPolicyHeader sub_head="7 Collection and use of information" />
          <Block>
            We may collect, hold, use and disclose information for the following
            purposes and personal information will not be further processed in a
            manner that is incompatible with these purposes:
          </Block>
          <BlockInner>
            1. to enable you to customise or personalise your experience of our
            software;
          </BlockInner>
          <BlockInner>
            2. to enable you to access and use our software, associated
            applications and associated social media platforms;
          </BlockInner>
          <BlockInner>3. to contact and communicate with you;</BlockInner>
          <BlockInner>
            4. for internal record keeping and administrative purposes;
          </BlockInner>
          <BlockInner>
            5. for analytics, market research and business development,
            including to operate and improve our software, associated
            applications and associated social media platforms;
          </BlockInner>
          <BlockInner>
            6. to run competitions and/or offer additional benefits to you
          </BlockInner>
          <BlockInner>
            7. for advertising and marketing, including sending you promotional
            information about our products and services and information about
            third parties that we consider may be of interest to you; and
          </BlockInner>
          <BlockInner>
            8. to comply with our legal obligations and resolve any disputes
            that we may have.
          </BlockInner>
          <TermsPolicyHeader sub_head="8. Disclosure of personal information to third parties " />
          <Block>We may disclose personal information to:</Block>
          <BlockInner>
            1. third party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, hosting and server providers, ad networks,
            analytics, error loggers, debt collectors, maintenance or
            problem-solving providers, marketing or advertising providers,
            professional advisors and payment systems operators;
          </BlockInner>
          <BlockInner>
            2. our employees, contractors and/or related entities; and{" "}
          </BlockInner>
          <BlockInner>
            3. Credit reporting agencies, courts, tribunals and regulatory
            authorities, in the event you fail to pay for goods or services we
            have provided to you.
          </BlockInner>
          <TermsPolicyHeader sub_head="9. International transfers of personal information" />
          <Block>
            1. The personal information we collect is stored and processed in
            the United States, or where we or our partners, affiliates and
            third-party providers maintain facilities. By providing us with your
            personal information, you consent to the disclosure to these
            overseas third parties.
          </Block>
          <Block>
            2. We will ensure that any transfer of personal information from
            countries in the European Economic Area (EEA) to countries outside
            the EEA will be protected by appropriate safeguards, for example by
            using standard data protection clauses approved by the European
            Commission, or the use of binding corporate rules or other legally
            accepted means.
          </Block>
          <Block>
            3. Where we transfer personal information from a non-EEA country to
            another country, you acknowledge that third parties in other
            jurisdictions may not be subject to similar data protection laws to
            the ones in our jurisdiction. There are risks if any such third
            party engages in any act or practise that would contravene the data
            privacy laws in our jurisdiction and this might mean that you will
            not be able to seek redress under our jurisdiction’s privacy laws.
          </Block>
          <TermsPolicyHeader sub_head="10. Your rights and controlling your personal information " />
          <Block>
            Choice and consent: By providing personal information to us, you
            consent to us collecting, holding, using and disclosing your
            personal information in accordance with this privacy policy. If you
            are under 16 years of age, you must have and warrant to the extent
            permitted by law to us, that you have your parent or legal
            guardian’s permission to access and use the software and they (your
            parents or guardian) have consented to you providing us with your
            personal information. You do not have to provide personal
            information to us, however, if you do not, it may affect your use of
            this software or the products and/or services offered on or through
            it.
          </Block>
          <TermsPolicyHeader sub_head="11. Information from third parties" />
          <Block>
            If we receive personal information about you from a third party, we
            will protect it as set out in this privacy policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.
          </Block>
          <TermsPolicyHeader sub_head="12. Restrict" />
          <Block>
            You may choose to restrict the collection or use of your personal
            information. If you have previously agreed to us using your personal
            information for direct marketing purposes, you may change your mind
            at any time by contacting us using the details below. If you ask us
            to restrict or limit how we process your personal information, we
            will let you know how the restriction affects your use of our
            software or products and services.
          </Block>
          <TermsPolicyHeader sub_head="13. Access data and portability " />
          <Block>
            You may request details of the personal information that we hold
            about you. You may request a copy of the personal information we
            hold about you. Where possible, we will provide this information in
            CSV format or another easily readable machine format. You may
            request that we erase the personal information we hold about you at
            any time. You may also request that we transfer this personal
            information to another third party.
          </Block>
          <TermsPolicyHeader sub_head="14. Correction" />
          <Block>
            If you believe that any information we hold about you is inaccurate,
            out of date, incomplete, irrelevant or misleading, please contact us
            using the details below. We will take reasonable steps to correct
            any information found to be inaccurate, incomplete, misleading or
            out of date.
          </Block>
          <TermsPolicyHeader sub_head="15. Notification of data breaches" />
          <Block>
            We will comply with laws applicable to us in respect of any data
            breach.
          </Block>
          <TermsPolicyHeader sub_head="16. Conplaint " />
          <Block>
            If you believe that we have breached a relevant data protection law
            and wish to make a complaint, please contact us using the details
            below and provide us with full details of the alleged breach. We
            will promptly investigate your complaint and respond to you, in
            writing, setting out the outcome of our investigation and the steps
            we will take to deal with your complaint. You also have the right to
            contact a regulatory body or data protection authority in relation
            to your complaint.
          </Block>
          <TermsPolicyHeader sub_head="17. Unsubscribe" />
          <Block>
            To unsubscribe from our email database or opt-out of communications
            (including marketing communications), please contact us using the
            details below or opt-out using the opt-out facilities provided in
            the communication.
          </Block>
          <TermsPolicyHeader sub_head="18. Cookies" />
          <Block>
            We use “cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our software
            stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content
            based on the preferences you have specified.
          </Block>
          <TermsPolicyHeader sub_head="19. Business transfer" />
          <Block>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data among
            the assets transferred to any parties who acquire us. You
            acknowledge that such transfers may occur and that any parties who
            acquire us may continue to use your personal information according
            to this policy.
          </Block>
          <TermsPolicyHeader sub_head="20. Limits of our policy " />
          <Block>
            Our software may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </Block>
          <TermsPolicyHeader sub_head="21. Changes to our policy" />
          <Block>
            At our discretion, we may change our privacy policy to reflect
            current acceptable practices. We will take reasonable steps to let
            users know about changes via our software. Your continued use of
            this site after any changes to this policy will be regarded as
            acceptance of our practices around privacy and personal information.
          </Block>
          <Block>
            If we make a significant change to this privacy policy, for example
            changing a lawful basis on which we process your personal
            information, we will ask you to re-consent to the amended privacy
            policy.
          </Block>
          <TermsPolicyHeader sub_head="22. Contact Us" />
          <Block>
            If you have any questions about these Terms, please contact us over
            live chat or email support@metricks.io
          </Block>
          <Footer />
        </Content>
      </PrivacyComponent>
    </>
  );
};

export default Privacy;
